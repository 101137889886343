import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getLogger } from '../index.js';
import { bareClient } from './request.js';
const login = (params) => bareClient.post('api/user/token', { json: params }).json();
class Token {
    _logger;
    _accessToken;
    _refreshToken;
    _user;
    _padding;
    constructor() {
        this._logger = getLogger('token');
        this._logger.enabled = true;
        this._setToken(); // fill with default value
    }
    _setToken(login) {
        this._accessToken = login?.token || '';
        this._refreshToken = login?.refresh || '';
        this._user = Token.parse(this._accessToken);
        if (login) {
            this._logger('set login', login);
            this.triggerChange(this._user);
        }
        else {
            this._logger('empty login');
        }
    }
    async initToken(token) {
        const tokens = await login({ token, type: 'Google' });
        this._setToken(tokens);
        return this._user;
    }
    async refreshToken(token) {
        if (!this._refreshToken && !token) {
            throw new Error('No authorization token.');
        }
        if (!this._padding) {
            this._padding = login({
                type: 'Refresh',
                token: this._refreshToken || token,
            });
        }
        this._setToken(await this._padding);
        this._padding = undefined;
    }
    get token() {
        return this._accessToken;
    }
    get refresh() {
        return this._refreshToken;
    }
    get isLogin() {
        return !!this._refreshToken;
    }
    get isExpired() {
        if (!this._user)
            return true;
        return Date.now() - this._user.create_at > this._user.exp;
    }
    static parse(token) {
        try {
            return JSON.parse(String.fromCharCode.apply(null, Array.from(Uint8Array.from(window.atob(
            // split jwt
            token.split('.')[1]), c => c.charCodeAt(0)))));
        }
        catch (error) {
            return null;
        }
    }
    callbacks = [];
    lastState = null;
    triggerChange(user) {
        this.lastState = user;
        this.callbacks.forEach(callback => callback(user));
    }
    onChange(callback) {
        this.callbacks.push(callback);
        callback(this.lastState);
    }
    offChange(callback) {
        const index = this.callbacks.indexOf(callback);
        if (index > -1) {
            this.callbacks.splice(index, 1);
        }
    }
}
export const token = new Token();
export const getAuthorizer = () => {
    const app = initializeApp({
        apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
        authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
        storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
        measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    });
    try {
        const firebaseAuth = getAuth(app);
        const googleAuthProvider = new GoogleAuthProvider();
        const getToken = async () => {
            const currentUser = firebaseAuth.currentUser;
            if (currentUser) {
                await currentUser.getIdTokenResult(true);
                if (!currentUser.isAnonymous) {
                    return currentUser.getIdToken();
                }
            }
            return;
        };
        const signInWithGoogle = async () => {
            const idToken = await getToken();
            if (idToken) {
                await token.initToken(idToken);
            }
            else {
                const user = await signInWithPopup(firebaseAuth, googleAuthProvider);
                const idToken = await user.user.getIdToken();
                await token.initToken(idToken);
            }
            return firebaseAuth.currentUser;
        };
        const onAuthStateChanged = (callback) => {
            firebaseAuth.onAuthStateChanged(callback);
        };
        return [signInWithGoogle, onAuthStateChanged];
    }
    catch (e) {
        return [];
    }
};
