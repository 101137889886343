import { createStore, del, get, keys, set, setMany, clear, entries, } from 'idb-keyval';
const initialIndexedDB = (database) => {
    const store = createStore(`affine:${database}`, 'database');
    return {
        get: (key) => get(key, store),
        set: (key, value) => set(key, value, store),
        setMany: (values) => setMany(values, store),
        keys: () => keys(store),
        entries: () => entries(store),
        delete: (key) => del(key, store),
        clear: () => clear(store),
    };
};
const scopedIndexedDB = () => {
    const idb = initialIndexedDB('global');
    const storeCache = new Map();
    return (scope) => {
        if (!storeCache.has(scope)) {
            const prefix = `${scope}:`;
            const store = {
                get: async (key) => idb.get(prefix + key),
                set: (key, value) => idb.set(prefix + key, value),
                setMany: (values) => idb.setMany(values.map(([k, v]) => [`${scope}:${k}`, v])),
                keys: () => idb
                    .keys()
                    .then(keys => keys
                    .filter(k => k.startsWith(prefix))
                    .map(k => k.slice(prefix.length))),
                entries: () => idb
                    .entries()
                    .then(entries => entries
                    .filter(([k]) => k.startsWith(prefix))
                    .map(([k, v]) => [k.slice(prefix.length), v])),
                delete: (key) => idb.delete(prefix + key),
                clear: async () => {
                    await idb
                        .keys()
                        .then(keys => Promise.all(keys.filter(k => k.startsWith(prefix)).map(k => del(k))));
                },
            };
            storeCache.set(scope, store);
        }
        return storeCache.get(scope);
    };
};
let lazyKVConfigure = undefined;
export const getKVConfigure = (scope) => {
    if (!lazyKVConfigure) {
        lazyKVConfigure = scopedIndexedDB();
    }
    return lazyKVConfigure(scope);
};
