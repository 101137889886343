"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logger = void 0;
const react_1 = require("react");
const Logger = () => {
    (0, react_1.useEffect)(() => {
        console.log('@affine/logger: Render Track');
    }, []);
    return null;
};
exports.Logger = Logger;
