import debug from 'debug';
import { DataCenter } from './datacenter.js';
const _initializeDataCenter = () => {
    let _dataCenterInstance;
    return (debug = true) => {
        if (!_dataCenterInstance) {
            _dataCenterInstance = DataCenter.init(debug);
            _dataCenterInstance.then(dc => {
                try {
                    if (window) {
                        window.dc = dc;
                    }
                }
                catch (_) {
                    // ignore
                }
                return dc;
            });
        }
        return _dataCenterInstance;
    };
};
export const getDataCenter = _initializeDataCenter();
export function getLogger(namespace) {
    const logger = debug(namespace);
    logger.log = console.log.bind(console);
    return logger;
}
export { WorkspaceType } from './apis/index.js';
