export class BaseProvider {
    static id = 'base';
    _apis;
    _config;
    _logger;
    _signals;
    _workspace;
    constructor() {
        // Nothing to do here
    }
    get id() {
        return this.constructor.id;
    }
    async init(params) {
        this._apis = params.apis;
        this._config = params.config;
        this._logger = params.logger;
        this._signals = params.signals;
        this._workspace = params.workspace;
        this._logger.enabled = params.debug;
    }
    async clear() {
        await this.destroy();
        await this._config.clear();
    }
    async destroy() {
        // Nothing to do here
    }
    async initData() {
        throw Error('Not implemented: initData');
    }
    // should return a blob url
    async getBlob(_id) {
        throw Error('Not implemented: getBlob');
    }
    // should return a blob unique id
    async setBlob(_blob) {
        throw Error('Not implemented: setBlob');
    }
    get workspace() {
        return this._workspace;
    }
    static async auth(_config, logger, _signals) {
        logger("This provider doesn't require authentication");
    }
    // get workspace list，return a map of workspace id and boolean
    // if value is true, it exists locally, otherwise it does not exist locally
    static async list(_config) {
        throw Error('Not implemented: list');
    }
}
