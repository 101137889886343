import { bareClient, client } from './request.js';
export var WorkspaceType;
(function (WorkspaceType) {
    WorkspaceType[WorkspaceType["Private"] = 0] = "Private";
    WorkspaceType[WorkspaceType["Normal"] = 1] = "Normal";
})(WorkspaceType || (WorkspaceType = {}));
export var PermissionType;
(function (PermissionType) {
    PermissionType[PermissionType["Read"] = 0] = "Read";
    PermissionType[PermissionType["Write"] = 1] = "Write";
    PermissionType[PermissionType["Admin"] = 2] = "Admin";
    PermissionType[PermissionType["Owner"] = 99] = "Owner";
})(PermissionType || (PermissionType = {}));
export async function getWorkspaces() {
    return client
        .get('api/workspace', {
        headers: {
            'Cache-Control': 'no-cache',
        },
    })
        .json();
}
export async function getWorkspaceDetail(params) {
    return client.get(`api/workspace/${params.id}`).json();
}
export async function getWorkspaceMembers(params) {
    return client.get(`api/workspace/${params.id}/permission`).json();
}
export async function createWorkspace(params) {
    return client.post('api/workspace', { json: params }).json();
}
export async function updateWorkspace(params) {
    return client
        .post(`api/workspace/${params.id}`, {
        json: {
            public: params.public,
        },
    })
        .json();
}
export async function deleteWorkspace(params) {
    await client.delete(`api/workspace/${params.id}`);
}
/**
 * Notice: Only support normal(contrast to private) workspace.
 */
export async function inviteMember(params) {
    return client
        .post(`api/workspace/${params.id}/permission`, {
        json: {
            email: params.email,
        },
    })
        .json();
}
export async function removeMember(params) {
    await client.delete(`api/permission/${params.permissionId}`);
}
export async function acceptInviting(params) {
    await bareClient.post(`api/invitation/${params.invitingCode}`);
}
export async function uploadBlob(params) {
    return client.put('api/blob', { body: params.blob }).text();
}
export async function getBlob(params) {
    return client.get(`api/blob/${params.blobId}`).arrayBuffer();
}
export async function leaveWorkspace({ id }) {
    await client.delete(`api/workspace/${id}/permission`).json();
}
export async function downloadWorkspace(workspaceId) {
    return client.get(`api/workspace/${workspaceId}/doc`).arrayBuffer();
}
